import { useNavigate } from "react-router";
import MainLayout from "../../layouts/MainLayout";
import styles from "./styles.module.scss";

const OilProductionOptimization = () => {
  const navigate = useNavigate();

  const onMainPage = () => {
    navigate("/");
  };

  return (
    <MainLayout>
      <div className={styles.main}>
        <div>
          <h1 className={styles.head}>
            Автоматическое Формирование сценариев разработки
          </h1>
          <h2 className={styles.sub}>
            Формирование псевдооптимального сценария в зависимости от заданной
            целевой функции
          </h2>
        </div>

        <button className={styles.show} onClick={onMainPage}>
          ← Вернуться обратно
        </button>
      </div>

      <div className={styles.advantages}></div>
      <div className={styles.problems}></div>
      <div className={styles.group}></div>
    </MainLayout>
  );
};

export default OilProductionOptimization;
