import ru from '../../locales/ru/translations.json'
import styles from './styles/Partners.module.scss'
import ItemsCarousel from 'react-items-carousel'
import { useState } from 'react'

const Partners = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  const onPrev = () => {
    console.log()
  }

  const onNext = () => {
    console.log()
  }

  const partners = [
    'https://geo.kpfu.ru/',
    'https://innopolis.university/',
    'https://ncmu.kpfu.ru/',
    'https://www.kmge.kz/',
    'https://www.geoindicator.com/',
    'https://www.tatneft.ru/',
    'https://www.gazprom-neft.ru/',
    'https://ntc.gazprom-neft.ru/',
  ]

  return (
    <section className={styles.wrapper}>
      <h2 className='section__head' id='section_3'>
        {ru.partners.head}
      </h2>
      <div className={styles.carousel}>
        {window.innerWidth <= 600 ? (
          <>
            {partners.map((link, key) => (
              <a href={link} target='_blank' rel='noreferrer' key={key}>
                <img src={`images/partners/${key + 1}.svg`} alt='partner' />
              </a>
            ))}
          </>
        ) : (
          <ItemsCarousel
            placeholderItem={
              <div style={{ height: 200, background: '#EEE' }} />
            }
            enablePlaceholder={true}
            numberOfPlaceholderItems={3}
            numberOfCards={3}
            gutter={12}
            slidesToScroll={1}
            chevronWidth={100}
            outsideChevron={true}
            showSlither={false}
            firstAndLastGutter={false}
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button className={styles.right_arrow} onClick={onNext}>
                <img src='images/arrow.svg' alt='arrow' />
              </button>
            }
            leftChevron={
              <button onClick={onPrev}>
                <img src='images/arrow.svg' alt='arrow' />
              </button>
            }
          >
            {partners.map((link, key) => (
              <a href={link} target='_blank' rel='noreferrer' key={key}>
                <img
                  className={styles.partners_image}
                  src={`images/partners/${key + 1}.svg`}
                  alt='partner'
                />
              </a>
            ))}
          </ItemsCarousel>
        )}
      </div>
    </section>
  )
}

export default Partners
