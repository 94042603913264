import React, { useEffect } from "react";
import styles from "./TagCanvas.module.scss";

interface Tag {
  src: string;
  id: number;
}

interface Props {
  tags: Tag[];
  getActiveId?: (id: number) => void;
  canvasId: string;
  setActiveId: (id: number | ((id: number) => number)) => void;
}

function TagCanvas({ tags, canvasId, setActiveId }: Props) {
  const tagsId = React.useId().replaceAll(":", "");

  useEffect(() => {
    if (window.TagCanvas) {
      window.TagCanvas.outlineColour = "rgba(0, 0, 0,  0)";

      setTimeout(
        () =>
          window.TagCanvas.Start(canvasId, tagsId, {
            reverse: true,
            depth: 0.6,
            dragControl: true,
            wheelZoom: false,
            minBrightness: 0.1,
            outlineMethod: "block",
            inital: [0.8, -0.3],
            maxSpeed: 0.002,
            imageScale: 4,
            imagePadding: 1,
          }),
        1000
      );
    }
  }, [canvasId, tagsId]);

  useEffect(() => {
    if (window.TagCanvas) {
      window.TagCanvas.SetSpeed(canvasId, [0.5, -0.25]);
    }
  }, []);

  return (
    <>
      <canvas
        width="600"
        height="719"
        id={canvasId}
        style={{ marginTop: "20px" }}
      ></canvas>
      <div id={tagsId}>
        <ul>
          {tags.map((tag, key) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={key}
              onClick={(e) => {
                e.preventDefault();
                setActiveId(tag.id);
              }}
            >
              <li>
                <img
                  className={styles.image}
                  src={tag.src}
                  alt="tag"
                  style={{ width: "24px", height: "24px" }}
                />
              </li>
            </a>
          ))}
        </ul>
      </div>
    </>
  );
}

export default TagCanvas;
