import styles from './styles/Product.module.scss'
import ru from '../../../locales/ru/translations.json'

interface Props {
  isActive?: boolean
  title: string
  description: string
  result: string
  experience: string
  imgIndex: number
  index: number
  onClick: (index: number) => void
  link?: string
}

const Product = ({
  isActive = false,
  title,
  description,
  result,
  experience,
  imgIndex,
  index,
  onClick,
  link = '',
}: Props) => {
  return (
    <div className={styles.main} onClick={() => onClick(index)}>
      <div className={styles.wrapper}>
        <img
          className={`${styles.image} ${isActive ? styles.image__active : ''}`}
          src={`images/products/${imgIndex}.svg`}
          alt='product'
        />
        <div
          className={`${styles.title__open} ${
            isActive ? styles.title__open__active : ''
          }`}
        >
          <h2
            className={styles.title}
            style={
              isActive
                ? { fontSize: '25px', marginTop: '75px', marginBottom: '75px' }
                : {}
            }
          >
            {title}
          </h2>
          <button
            className={`${styles.open} ${isActive ? styles.open__active : ''}`}
          >
            <img
              className={styles.arrow}
              src='images/arrow.svg'
              alt='open arrow'
            />
          </button>
        </div>
      </div>
      {isActive && (
        <>
          <div className={styles.active__block}>
            <div className={styles.description}>{description}</div>
            <h3 className={styles.result}>{ru.products.detailed.result}:</h3>
            <div className={styles.result__text}>{result}</div>
            {experience.length > 0 && (
              <h3 className={styles.exp}>{ru.products.detailed.experience}:</h3>
            )}
            <div className={styles.exp__text}>{experience}</div>

            {link && link.length > 0 && (
              <>
                <h3 className={styles.link__title}>Ссылка на приложение</h3>
                <a className={styles.link} href={link}>
                  {link}
                </a>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Product
