import { useLocation, useNavigate } from 'react-router'
import { bubble as Menu } from 'react-burger-menu'
import ScrollTo from 'react-scroll-into-view'

import ru from '../../../locales/ru/translations.json'
import styles from './Tabs.module.scss'
import { useEffect, useState } from 'react'

const Tabs = () => {
  const products = document.querySelector('#products')
  const [isOpen, setOpen] = useState(false)

  const location = useLocation()

  const burgerButton = document.querySelector('.bm-burger-button')

  const navigate = useNavigate()

  const onClickTabName = (tabName: string) => {
    closeSideBar()

    if (
      tabName === 'Оптимизация добычи нефти' &&
      !(location.pathname === '/oil-production-optimization')
    ) {
      navigate('/oil-production-optimization')
	} else {
		navigate('/')
	}

    if (tabName === 'Продукты и сервисы') {
      navigate('/')
    }

    if (products) {
      products.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  const onMainPage = () => {
    navigate('/')
    closeSideBar()
  }

  useEffect(() => {
    if (isOpen) {
      burgerButton?.lastElementChild?.setAttribute(
        'src',
        'images/header/close.svg'
      )
    }

    if (!isOpen) {
      burgerButton?.lastElementChild?.setAttribute('src', 'images/burger.svg')
    }
  }, [burgerButton, isOpen])

  return (
    <>
      <>
        <div className={styles.mobile_wrapper}>
          <Menu
            noTransition
            left
            customBurgerIcon={
              <img src='images/burger.svg' alt='burger menu icon' />
            }
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
          >
            <ul className={styles.tabs}>
              <button
                className={`${styles.tab} ${styles.button__home}`}
                onClick={onMainPage}
              >
                <li className={styles.home}>ГЛАВНАЯ</li>
                <img src='images/home.svg' alt='home' />
              </button>

              {ru.header.tabs.map((tabName, key) => (
                <ScrollTo selector={`#section_${key}`} key={key}>
                  <button className={styles.tab}>
                    <li onClick={() => onClickTabName(tabName)}>{tabName}</li>
                  </button>
                  <button className={styles.arrow_mobile}>
                    <img
                      className={styles.arrow}
                      src='images/arrow.svg'
                      alt='open arrow'
                    />
                  </button>
                </ScrollTo>
              ))}

              <button className={styles.locale}>Ru/En</button>
            </ul>
          </Menu>
        </div>

        <ul className={styles.tabs}>
          {ru.header.tabs.map((tabName, key) => (
            <ScrollTo selector={`#section_${key}`} key={key}>
              <button className={styles.tab}>
                <li onClick={() => onClickTabName(tabName)}>{tabName}</li>
              </button>
            </ScrollTo>
          ))}
        </ul>
      </>
    </>
  )
}

export default Tabs
