import { useCallback, useRef } from "react";
import Particles from "react-particles";
import { loadLinksPreset } from "tsparticles-preset-links";
import type { Engine } from "tsparticles-engine";
import ScrollTo from "react-scroll-into-view";

import LogoBlock from "../../components/LogoBlock/LogoBlock";
import styles from "./Footer.module.scss";
import Link from "./Link/Link";
import ru from "../../locales/ru/translations.json";

const Footer = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadLinksPreset(engine);
  }, []);

  const particlesContainer = useRef(null);

  const LINKS = ru.footer.links;

  return (
    <>
      <footer
        className={`${styles.wrapper} footer `}
        ref={particlesContainer}
        id="particles-js"
      >
        {!(window.innerWidth <= 600) && (
          <Particles
            className="particles-wrapper"
            id="tsparticles"
            container={particlesContainer}
            style={{ zIndex: -1 }}
            init={particlesInit}
            width="100%"
            height="700px"
            options={{
              fullScreen: false,
              background: {
                color: {
                  value: "transparent",
                },
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "push",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 9,
                  },
                },
              },
              preset: "links",
              particles: {
                links: {
                  color: "#A9A9A9",
                  opacity: 0.6,
                },
                color: {
                  value: "#303030",
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 0.3,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "square",
                },
                size: {
                  value: { min: 0.1, max: 0.1 },
                },
              },
              detectRetina: true,
            }}
          />
        )}

        <div id="section_4">
          <LogoBlock isFooter />
          <button className={styles.button__mission}>
            {ru.footer.mission.title}
          </button>
          <span className={styles.mission}>{ru.footer.mission.value}</span>
        </div>
        <div className={styles.relative}>
          <div className={styles.links}>
            {LINKS.map((link, key) => (
              <div
                className={`${key % 2 === 0 ? "" : styles.ml} ${
                  key > 1 ? styles.mt : ""
                }`}
                key={key}
              >
                <Link
                  isLine90Deg={key > 0}
                  isAddress={key === 3}
                  target={key > 1 ? "__blank" : ""}
                  {...link}
                />
              </div>
            ))}
          </div>
          <ScrollTo selector={"#header"}>
            <div className={styles.back}>
              <button className={styles.button__back}>{ru.footer.back}</button>
              <img
                className={styles.arrow}
                src="images/arrow.svg"
                alt="arrow"
              />
            </div>
          </ScrollTo>
        </div>
      </footer>
    </>
  );
};

export default Footer;
