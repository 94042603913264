import Main from './sections/Main/Main'
import Partners from './sections/Partners/Partners'
import Products from './sections/Products/Products'

function App() {
  return (
    <>
      <Main />
      <Products />
      <Partners />
    </>
  )
}

export default App
