import { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import ru from '../../locales/ru/translations.json'
import Product from './Product/Product'

import styles from './styles/ProductsPage.module.scss'

interface Props {
  isMobile?: boolean
}

const ProductsPage = ({ isMobile = false }: Props) => {
  const [activeProducts, setActiveProducts] = useState<number[]>([])
  const [products, setProducts] = useState(Object.values(ru.products.texts))

  useEffect(() => {
    setProducts(Object.values(ru.products.texts))
  }, [])

  const onProductClick = (index: number) => {
    if (activeProducts.includes(index)) {
      setActiveProducts((prev) => prev.filter((product) => product !== index))
    }

    if (!activeProducts.includes(index)) {
      setActiveProducts((prev) => [...prev, index])
    }
  }

  return (
    <MainLayout>
      <h1 className={styles.head}>{ru.products.head}</h1>
      <a
        className={styles.hide}
        href={
          process.env.REACT_APP_PROD === 'true'
            ? '/' + process.env.REACT_APP_MAIN_PAGE
            : '/'
        }
      >
        {ru.products.hide}
      </a>
      {products.map((p, key) => (
        <Product
          key={key}
          imgIndex={key + 1}
          index={key}
          onClick={onProductClick}
          isActive={activeProducts.includes(key)}
          {...p}
        />
      ))}
    </MainLayout>
  )
}

export default ProductsPage
