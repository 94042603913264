import styles from "./Main.module.scss";
import ru from "../../locales/ru/translations.json";

const Main = () => {
  return (
    <div className={styles.main}>
      <div>
        <div className={styles.head}>
          <h1 className={`${styles.head__text} ${styles.head}`}>
            {ru.main.h1[1]}
          </h1>
          <h1 className={`${styles.head__text} ${styles.subhead__auto}`}>
            {ru.main.h1[2]["2.1"]}
          </h1>
          <h1
            className={`${styles.head__text} ${styles.subhead__auto} ${styles.subhead__auto__second}`}
          >
            {ru.main.h1[2]["2.2"]}
          </h1>
          <h1 className={`${styles.head__text} ${styles.foot}`}>
            {ru.main.h1[3]}
          </h1>

          <img className={styles.line} src="images/line.svg" alt="line" />
        </div>
      </div>
      <img className={styles.main__svg} src="images/main.svg" alt="main" />
    </div>
  );
};

export default Main;
