import { createBrowserRouter } from "react-router-dom";
import MainPage from "./pages/MainPage";
import ProductsPage from "./pages/ProductsPage/ProductsPage";
import OilProductionOptimization from "./pages/OilProductionOptimization/OilProductionOptimization";

const router = createBrowserRouter([
  {
    path:
      process.env.REACT_APP_PROD === "true"
        ? process.env.REACT_APP_MAIN_PAGE
        : "/",
    element: <MainPage />,
    // element: <OilProductionOptimization />,
  },
  {
    path:
      process.env.REACT_APP_PROD === "true"
        ? process.env.REACT_APP_PRODUCTS_PAGE
        : "products",
    element: <ProductsPage />,
  },
  {
    path:
      process.env.REACT_APP_PROD === "true"
        ? process.env.REACT_APP_OIL_PRODUCTION_OPTIMIZATION_PAGE
        : "oil-production-optimization",
    element: <OilProductionOptimization />,
  },
]);

export default router;
