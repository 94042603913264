import ru from '../../locales/ru/translations.json'
import styles from './LogoBlock.module.scss'

interface Props {
  isFooter?: boolean
}

const LogoBlock = ({ isFooter = false }: Props) => {
  const pathNavigate =
    process.env.REACT_APP_PROD === 'true'
      ? '/' + process.env.REACT_APP_MAIN_PAGE
      : '/'

  return (
    <div className={styles.left}>
      <a className={styles.left} href={pathNavigate}>
        <img
          className={isFooter ? styles.footer__logo : ''}
          src='images/header/logo.svg'
          alt='geopy logo'
        />
        <img
          className={`${styles.line} ${isFooter ? styles.footer__line : ''}`}
          src='images/header/vertical-line.svg'
          alt='vertical line'
        />
        <h2
          className={`${styles.description} ${
            isFooter ? styles.footer__description : ''
          }`}
        >
          {ru.header.description}
        </h2>
      </a>
    </div>
  )
}

export default LogoBlock
