import styles from "./DetailedInformation.module.scss";
import ru from "../../../locales/ru/translations.json";

interface Props {
  title: string;
  description: string;
  result: string;
  experience?: string;
  setActiveId: (id: number | ((id: number) => number)) => void;
}

const DetailedInformation = ({
  title,
  description,
  result,
  experience = "",
  setActiveId,
}: Props) => {
  const onPrev = () => {
    setActiveId((prev) => {
      if (prev > 1) {
        return prev - 1;
      }

      return 27;
    });
  };

  const onNext = () => {
    setActiveId((prev) => {
      if (prev < 27) {
        return prev + 1;
      }

      return 1;
    });
  };

  return (
    <div className={styles.text__block}>
      <div className={styles.detailed}>
        <h3 className={styles.title}>{title}</h3>
        <span>{description}</span>
        <div className={styles.result}>
          <h4>{ru.products.detailed.result}</h4>
          <span>{result}</span>
        </div>
        {experience.length > 0 && (
          <div className={styles.experience}>
            <h4>{ru.products.detailed.experience}</h4>
            <span>{experience}</span>
          </div>
        )}
      </div>
      <div className={styles.arrows}>
        <button onClick={onPrev}>
          <img src="images/arrow.svg" alt="arrow" />
        </button>
        <button className={styles.right_arrow} onClick={onNext}>
          <img src="images/arrow.svg" alt="arrow" />
        </button>
      </div>
    </div>
  );
};

export default DetailedInformation;
