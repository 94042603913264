import Header from '../components/Header/Header'
import Footer from '../sections/Footer/Footer'

interface Props {
  children?: React.ReactNode
}

const MainLayout = ({ children }: Props): JSX.Element => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default MainLayout
