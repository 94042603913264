import { HTMLAttributeAnchorTarget } from 'react'
import styles from './Link.module.scss'

interface Props {
  title: string
  href: string
  value: string
  target?: HTMLAttributeAnchorTarget

  isLine90Deg: boolean
  isAddress: boolean
}

const Telephone = ({
  title,
  href,
  value,
  target,
  isLine90Deg = false,
  isAddress = false
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title__wrapper}>
        <span className={styles.title}>{title}</span>
        <img
          className={`${styles.line} ${isLine90Deg ? styles.transform_90 : ''}`}
          src='images/header/vertical-line.svg'
          alt='line'
        />
      </div>
      <a
        className={`${styles.link} ${isAddress ? styles.address : ''}`}
        href={href}
        target={target}
        rel='noreferrer'
      >
        {value}
      </a>
    </div>
  )
}

export default Telephone
