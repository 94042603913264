import App from '../App'
import MainLayout from '../layouts/MainLayout'

const MainPage = () => {
  return (
    <MainLayout>
      <App />
    </MainLayout>
  )
}

export default MainPage
