import styles from "./Header.module.scss";
import LogoBlock from "../LogoBlock/LogoBlock";
import Tabs from "./Tabs/Tabs";

const Header = () => {
  return (
    <header
      className={styles.wrapper}
      id="header"
    >
      <LogoBlock />
      <Tabs />
      <button className={styles.locale}>Ru/En</button>
    </header>
  );
};

export default Header;
