import { useEffect, useState } from 'react'
import ru from '../../locales/ru/translations.json'
import TagCanvas from '../../components/TagCanvas/TagCanvas'
import DetailedInformation from './DetailedInformation/DetailedInformation'
import styles from './Products.module.scss'
import React from 'react'
import { useNavigate } from 'react-router'
import Product from '../../pages/ProductsPage/Product/Product'

const Products = () => {
  const [activeId, setActiveId] = useState<number>(0)
  const canvasId = React.useId().replaceAll(':', '')
  const [activeProducts, setActiveProducts] = useState<number[]>([])

  useEffect(() => {
    console.log('activeId', activeId)
  }, [activeId])

  const products = Object.values(ru.products.texts)

  useEffect(() => {
    console.log('products', products)
  }, [products])

  const navigate = useNavigate()

  const onProductClick = (index: number) => {
    if (activeProducts.includes(index)) {
      setActiveProducts((prev) => prev.filter((product) => product !== index))
    }

    if (!activeProducts.includes(index)) {
      setActiveProducts((prev) => [...prev, index])
    }
  }

  const activeProduct =
    ru.products.texts[
      String(
        activeId === 0 ? activeId : activeId - 1
      ) as keyof typeof ru.products.texts
    ]

  function createArray() {
    const array = []
    for (let i = 0; i < 28; i++) {
      const obj = {
        src: 'images/products/' + i + '.svg',
        id: i,
      }
      array.push(obj)
    }
    return array
  }

  const tags = createArray()

  console.log(tags, 'tags')

  useEffect(() => {
    window.TagCanvas?.TagToFront(canvasId, {
      index: activeId,
    })
  }, [activeId])

  useEffect(() => {
    window.TagCanvas?.TagToFront(canvasId, {
      index: 1,
    })
  }, [])

  const onProductsPage = () => {
    navigate('products')
  }

  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 600

  return (
    <section className={styles.products}>
      <h2 className={styles.text__head} id='section_1'>
        {ru.products.head}
      </h2>
      <button className={styles.show} onClick={onProductsPage}>
        {ru.products.show}
      </button>
      <div className={styles.body}>
        {!isMobile && (
          <TagCanvas
            tags={tags}
            canvasId={canvasId}
            setActiveId={setActiveId}
          />
        )}

        <DetailedInformation setActiveId={setActiveId} {...activeProduct} />
      </div>

      <div className={styles.mobile__products}>
        {products.map((p, key) => (
          <Product
            key={key}
            imgIndex={key + 1}
            index={key}
            onClick={onProductClick}
            isActive={activeProducts.includes(key)}
            {...p}
          />
        ))}
      </div>
    </section>
  )
}

export default Products
